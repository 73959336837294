
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { mobileRulesEl, mobileRules, priceRulesEl, identityIdRulesEl, checkPlateCode } from "@/utils/verify";
import GeneralVehicleSelect from "@/components/scope/general-vehicle-select.vue";
import AreaSelect from "@/components/scope/area-select.vue";
import t from "@common/src/i18n/t";
const customer = namespace("customer");
const base = namespace("base");
const app = namespace("app");

@Component({
  components: { AreaSelect, GeneralVehicleSelect }
})
export default class CustomerEdit extends Vue {
  @customer.Action createCustomer;
  @customer.Action updateCustomer;
  @customer.Action saveCustomer;
  @customer.Action getCustomerDetail;
  @customer.Action updateVehicleOwner;
  @customer.Action getCustomerExpensesRecord;
  @customer.Action queryInsureList;
  @customer.Action addInsure;
  @base.Action getDictionaryList;
  @base.Action getVehicleInfoByVin;
  @app.State btnLoading;
  @app.Mutation btnStartLoading;
  @app.Mutation btnStopLoading;
  @app.Mutation setPBPX;
  get breadData() {
    return [
      { name: t("test.customer-information"), path: "/customer/index" },
      {
        name:
          this.$route.name === "customerAdd"
            ? t("setting.add-the-customer")
            : this.isChangeOwner
            ? t("v210906.change-owner")
            : t("setting.edit-the-customer")
      }
    ];
  }
  // LFV2A21K363553763
  vehicleCode: any = "";
  showSelectVehicleMode: boolean = false;
  vehicleListByVin: any = [];
  customerFormData = {
    vehicle: {
      plateCodePrev: t("setting.shanghai"),
      plateCodePost: "",
      plateCode: "",
      vinCode: "",
      vehicleCode: "",
      modelCode: "",
      price: "",
      engineCode: "",
      registerDate: "",
      inspectionExpireDate: "",
      insuranceExpireDate: "",
      insuranceCompany: "",
      lastMaintenanceMileage: "",
      nextMaintenanceMileage: "",
      nextMaintenanceDate: ""
    },
    customerName: "",
    customerCode: "",
    mobile: "",
    gender: "",
    customerId: "",
    birthday: "",
    source: "",
    area: [],
    address: "",
    type: "",
    companyName: "",
    settleMethod: "",
    memberFlag: 0,
    stateName: "",
    cityName: "",
    districtName: "",
    vehicleCode: ""
  };
  showMobileUsedTips: boolean = false;
  options: any[] = [];
  customerSourceList: any[] = []; // 数据字典：用户来源list
  customerTypeList: any[] = []; // 数据字典：用户类型list
  settleMethodList: any[] = []; // 数据字典：结算方式list
  insuranceCompanyList: any[] = []; // 数据字典：保险公司list
  insuranceCompanyListCopy: any[] = []; // 数据字典：保险公司list
  showAreaSelect: boolean = false; //控制地区信息set完成后，显示组件
  defaultVehicleList: any[] = [];
  loadEnd: boolean = false;
  customerDataByMobile: any = {};
  customerFormRules = {
    "vehicle.plateCode": [{ required: true, validator: this.validatePlateCode, trigger: "blur" }],
    "vehicle.price": [{ validator: this.checkVehiclePrice, trigger: "blur" }],
    "vehicle.modelCode": [{ required: false, message: t("test.select-car-model"), trigger: "blur" }],
    mobile: { required: false, validator: this.validateMobile, trigger: "blur" },
    customerName: { validator: this.validateCustomerName, trigger: "blur" },
    customerId: { validator: this.validateIdentityId, trigger: "blur" }
  };
  companyListCopy: any[] = [];
  async addNewCompany() {
    (this.$refs["companySelect"] as any).blur();
    const data = await this.addInsure({ insurerName: this.companySearchValue });
    // 添加新的保险公司;
    this.getCompanyList(() => {
      this.customerFormData.vehicle.insuranceCompany = this.companySearchValue;
      // this.$nextTick(() => {
      // 请求返回的数据，提交表单中的
      // this.customerFormData.vehicle.insuranceCompany = data.data.insurerCode; // 复制 重新渲染UI,返回的是一个新的列表
      // });
    });
  }
  companySearchValue: string = ""; // 保险公司搜索keyword
  handleSearchCompany(val) {
    if (val) {
      this.companySearchValue = val;
      this.insuranceCompanyList = this.insuranceCompanyList.filter(item => {
        return item.insurerName.includes(val);
      });
    } else {
      this.insuranceCompanyList = this.insuranceCompanyListCopy;
    }
  }
  get isAdd() {
    return this.$route.name === "customerAdd";
  }
  get isChangeOwner() {
    return !!this.$route.query.changeOwner;
  }
  editPlateCode: any = "";
  validatePlateCode(rule, value, callback) {
    const checkResult = checkPlateCode(value);
    if (!checkResult.success) {
      return callback(new Error(checkResult.msg));
    }
    if (!this.isAdd) {
      // 编辑不查车牌是否存在
      return callback();
    }
    // if (this.editPlateCode && this.editPlateCode === this.customerFormData.vehicle.plateCode) {
    //   // 编辑不查车牌是否存在
    //   return callback();
    // }
    this.getCustomerDetail({
      plateCode: this.customerFormData.vehicle.plateCode
    }).then(data => {
      if (data.data) {
        callback(new Error(t("setting.license-plate-number-already-exists")));
      } else {
        callback();
      }
    });
  }
  handleVinBlur() {
    if (!this.customerFormData.vehicle.vinCode) return;
    this.getVehicleInfoByVin({
      vinCode: this.customerFormData.vehicle.vinCode
    }).then(
      data => {
        if (data.data.length > 1) {
          this.showSelectVehicleMode = true;
          this.vehicleListByVin = data.data;
        } else if (data.data.length === 1) {
          this.handleSelectVehiclebyVin(data.data[0]);
        }
      },
      data => {
        this.$message.error(data.message);
        // (this.$refs.vehicleSelect as any).reset();
      }
    );
  }
  handleLinkChangeOwner() {
    let { href } = this.$router.resolve(
      `/customer/customer-edit/${this.$route.params.id}?vehicleCode=${this.vehicleCode}&changeOwner=1`
    );
    window.open(href, "_blank");
  }
  handleSelectVehiclebyVin(vehicleData) {
    this.showSelectVehicleMode = false;
    this.customerFormData.vehicle.price = vehicleData.price;
    this.defaultVehicleList = this.vehicleDataFormat([vehicleData]);
    this.$nextTick(() => {
      (this.$refs["carForm"] as any).validateField("vehicle.modelCode", function (errmsg) {
        console.log(errmsg);
      });
    });
  }
  created() {
    this.setPBPX(52);
    this.init();
  }
  validateMobile(rule, value, callback) {
    if (this.isChangeOwner) {
      callback();
    } else {
      if (!value) {
        callback();
      } else {
        callback();
      }
    }
  }
  validateCustomerName(rule, value, callback) {
    if (this.isChangeOwner) {
      if (!this.customerFormData.customerName) {
        callback(new Error(t("v210831.please-enter-customer")));
      } else {
        callback();
      }
    } else {
      callback();
    }
  }
  validateIdentityId(rule, value, callback) {
    if (!value) {
      callback();
    } else {
      identityIdRulesEl(value, callback);
    }
  }
  checkVehiclePrice(rule, value, callback) {
    if (!value) {
      callback();
    } else {
      priceRulesEl(value, callback);
    }
  }
  handleQueryCustomerExist() {
    // this.customerDataByMobile = {};
    if (this.isAdd || this.isChangeOwner) {
      this.getCustomerDetail({
        mobile: this.customerFormData.mobile
      }).then(data => {
        if (data.data) {
          this.showMobileUsedTips = true;
          delete data.data.vehicleList;
          this.customerDataByMobile = data.data;
        } else {
          // this.clearCustomerInfo();
          this.customerFormData.customerCode = "";
        }
      });
    }
  }
  clearCustomerInfo() {
    Object.keys(this.customerDataByMobile).forEach(item => {
      this.customerFormData[item] = "";
    });
    this.customerFormData.area = [];
    this.customerFormData.memberFlag = 0;
  }
  handleSetCustomerInfo() {
    this.showAreaSelect = false;
    Object.entries(this.customerDataByMobile).forEach(item => {
      this.customerFormData[item[0]] = item[1];
    });
    this.customerFormData.area = [
      this.customerDataByMobile.stateName,
      this.customerDataByMobile.cityName,
      this.customerDataByMobile.districtName
    ];
    this.$nextTick(() => {
      this.showAreaSelect = true;
    });
    this.showMobileUsedTips = false;
  }
  handleOpoColse() {
    (this.$refs["carForm"] as any).validateField("vehicle.modelCode");
  }
  handleAreaChange(val) {
    this.$nextTick(() => {
      this.$nextTick(() => {
        const labels = (this.$refs["areaCascader"] as any).getLabels();
        this.customerFormData.stateName = labels[0];
        this.customerFormData.cityName = labels[1];
        this.customerFormData.districtName = labels[2];
      });
    });
  }
  submitForm() {
    (this.$refs["carForm"] as any).validate(valid => {
      (this.$refs["customerForm"] as any).validate(valid2 => {
        if (valid && valid2) {
          this.btnStartLoading();
          if (this.isAdd) {
            this.addCustomer();
          } else {
            this.editCustomer();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    });
  }

  async init() {
    this.vehicleCode = this.$route.query.vehicleCode;
    this.getDictionaryList("customer_source").then(data => {
      this.customerSourceList = data;
    });
    this.getDictionaryList("customer_type").then(data => {
      this.customerTypeList = data;
    });
    this.getDictionaryList("settle_method").then(data => {
      this.settleMethodList = data;
    });
    this.getCompanyList();
    if (!this.isAdd) {
      const customerData = await this.getCustomerDetail({
        customerCode: this.$route.params.id,
        vehicleCode: this.vehicleCode
      });
      customerData.data.area = [
        customerData.data.stateName,
        customerData.data.cityName,
        customerData.data.districtName
      ];
      // 过滤车辆信息
      if (customerData.data.vehicleList.length) {
        // 如果有车辆信息
        customerData.data.vehicle = customerData.data.vehicleList.find(item => {
          return customerData.data.selectedVehicleCode === item.vehicleCode;
        });

        this.defaultVehicleList = this.vehicleDataFormat([customerData.data.vehicle]);
        this.customerFormData.vehicle.modelCode = this.defaultVehicleList[0].lastValue;
      } else {
        customerData.data.vehicle = {
          plateCodePrev: t("setting.shanghai"),
          plateCodePost: "",
          plateCode: "",
          vinCode: "",
          vehicleCode: "",
          modelCode: "",
          price: "",
          engineCode: "",
          registerDate: "",
          inspectionExpireDate: "",
          insuranceExpireDate: "",
          insuranceCompany: "",
          lastMaintenanceMileage: "",
          nextMaintenanceMileage: "",
          nextMaintenanceDate: ""
        };
      }
      this.customerFormData = customerData.data;
      this.editPlateCode = this.customerFormData.vehicle.plateCode || "";
      if (this.isChangeOwner) {
        let keyArr = Object.keys(this.customerFormData);
        keyArr.forEach(key => {
          if (key !== "vehicle") {
            this.customerFormData[key] = "";
          }
        });
        this.customerFormData["area"] = [];
        this.customerFormData["memberFlag"] = 0;
      }
    }
    this.showAreaSelect = true;
  }
  getCompanyList(callback?) {
    this.queryInsureList({ pageNum: "1", pageSize: 5, insurerNmae: "" }).then(data => {
      console.log(data, "data");
      this.insuranceCompanyList = data.data.list;
      this.insuranceCompanyListCopy = data.data.list;
      callback && callback();
    });
  }
  vehicleDataFormat(data) {
    return data.map(item => ({
      lastValue: item.modelCode,
      name: item.brandName + " / " + item.seriesName + " / " + item.modelDesc,
      valueGroup: [item.brandCode, item.seriesName, item.modelCode]
    }));
  }
  async addCustomer() {
    console.log("addCustomer", this.customerFormData);
    this.saveCustomer(this.customerFormData)
      .then(data => {
        const { customerCode, vehicleCode } = data.data;
        this.$message.success(t("base.success") as any);
        const { source, type } = this.$route.query;
        if (source == "serviceAdd") {
          this.$router.push({
            name: "serviceAdd",
            query: {
              type,
              plateCode: this.customerFormData.vehicle.plateCode,
              customerCode,
              vehicleCode
            }
          });
        } else {
          this.$router.push(`/customer/index`);
        }
      })
      .finally(() => {
        this.btnStopLoading();
      });
  }
  async editCustomer() {
    if (this.isChangeOwner) {
      this.customerFormData.vehicleCode = this.customerFormData.vehicle.vehicleCode;
      this.updateVehicleOwner(this.customerFormData)
        .then(data => {
          this.$message.success(t("v210906.owner-replaced"));
          this.$router.push(`/customer/index`);
        })
        .finally(() => {
          this.btnStopLoading();
        });
    } else {
      this.saveCustomer(this.customerFormData)
        .then(data => {
          this.$message.success(t("base.success") as any);
          this.$router.push(`/customer/index`);
        })
        .finally(() => {
          this.btnStopLoading();
        });
    }
  }
  backHandle(){
    this.$router.push(`/customer/index`)
  }
}
